<template>
  <div>
    <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
      「次回更新」は、自動更新中のみ表示されます。「更新する」を全てOFFにした場合、自動更新を停止します。
    </div>
    <v-card elevation="0">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="（検索）"
          single-line
          hide-details
          clearable
          dense
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="propsSitelist"
        :items-per-page="50"
        :footer-props="{
          'items-per-page-options': [50, 100, -1],
          'items-per-page-text': '表示件数',
        }"
        :mobile-breakpoint="0"
        item-key="uk"
        no-data-text="対応媒体が設定されていません"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
      <template v-slot:item.is_auto="{ item }">
          <div class="mx-1" @click="onChangeListIsAuto(item)">
            <v-icon
              v-if="item.is_auto"
              class="is_auto_cursor font-bold"
              color="primary"
              large
              >mdi-toggle-switch</v-icon
            >
            <v-icon v-else large class="is_auto_cursor font-bold">
              mdi-toggle-switch-off
            </v-icon>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
      <template v-slot:item.next_update="{ item }">
          <div v-if="working_is_auto">
            <div v-if="item.is_auto">
              <span class="next_time">{{
                utilDate.getTimeFormat(item.next_update)
              }}</span
              ><br />
              <span class="mini next_date">{{
                utilDate.getDateFormatShortDisplay(item.next_update)
              }}</span>
            </div>
            <div class="auto_off" v-else>--</div>
          </div>
          <div class="auto_off" v-else>--</div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.interval_minute="{ item }">
          <div class="pa-1">
            <span
              style="cursor: pointer"
              class="text-align-middle"
              @click="onChangeInterval(item)"
              ><v-icon>mdi-pencil</v-icon>
              &nbsp;
              <span class="text-align-middle"
                >{{ item.interval_minute }}分</span
              >
            </span>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.content_name="{ item }">
          <v-icon dense> {{ item.content_icon }} </v-icon
          ><span class="text-align-middle mini"
            >&nbsp;{{ item.content_name }}</span
          >
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.site_content_config="{ item }">
          <div
            class="pa-1"
            v-if="
              setting.working_status_required_config_ids.includes(
                item.site_content_id
              )
            "
          >
            <template v-if="item.config_check">
              <span style="cursor: pointer" class="text-align-middle">
                <v-icon color="success">mdi-check-circle-outline</v-icon>
              </span>
            </template>
            <template v-else>
              <span style="cursor: pointer" class="text-align-middle">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="error" v-bind="attrs" v-on="on"
                      >mdi-alert-circle-outline</v-icon
                    >
                  </template>
                  <span
                    >"更新設定"にてコンテンツの個別設定を確認してください</span
                  >
                </v-tooltip>
              </span>
            </template>
          </div>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.site_name="{ item }">
          <span :style="item.color.length != 0 ? 'color:' + item.color : ''">{{
            item.site_name
          }}</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.last_update="{ item }">
          <span class="mini">{{
            utilDate.getDatetimeFormatLongDisplay(item.last_update)
          }}</span>
        </template>
      </v-data-table>
    </v-card>
    <!-- 更新設定ダイアログ -->
    <v-dialog
      v-model="intervalDialog"
      scrollable
      persistent
      width="100%"
      max-width="1000px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >{{ site_name }}「{{ site_content_name }}」の更新に関する設定</span
          >
        </v-card-title>
        <v-card-text>
          <ValidationObserver ref="observerInterval">
            <h3>基本設定</h3>
            <div class="mt-2 mb-2">更新間隔と更新時間帯を設定して下さい。</div>
            <v-container fluid>
              <v-row>
                <v-col cols="6" sm="3">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="更新間隔(分)"
                    :rules="
                      `required|max:4|between:` +
                      min_interval +
                      `,` +
                      max_interval
                    "
                  >
                    <v-text-field
                      v-model="interval"
                      name="name"
                      label="更新間隔"
                      :error-messages="errors"
                      :success="valid"
                      suffix="分"
                      type="number"
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <p>更新する時間帯をONにして下さい。</p>
                  <div
                    v-for="num in time_zone_default_list"
                    :key="num"
                    class="time_zone_box"
                  >
                    <v-btn
                      :color="
                        time_zone_list.find((v) => v == num)
                          ? `primary`
                          : `grey lighten-3`
                      "
                      small
                      elevation="0"
                      class="pa-0 mr-1 mb-1"
                      @click="onClickTimeZone(num)"
                      >{{ num }}時</v-btn
                    >
                  </div>
                  <p>※店舗管理の更新時間帯で設定した内容が優先されます。</p>
                </v-col>
              </v-row>
            </v-container>
            <div
              v-if="
                setting.working_status_required_config_ids.includes(
                  site_content_id
                )
              "
            >
              <v-divider class="mt-4 mb-8"></v-divider>
              <h3>{{ site_content_name }}の個別設定</h3>
              <div class="mt-2 mb-2">
                該当コンテンツに関する設定を行なって下さい。更新時、デフォルの設定として使用します。<span
                  class="text-red"
                  >未設定の場合、自動更新が開始できません。</span
                >
              </div>
              <div>
                <component
                  v-bind:is="siteContent.component"
                  ref="refsiteContentComponent"
                  :com_data="siteContent"
                ></component>
              </div>
            </div>
          </ValidationObserver>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelIntervalDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitIntervalDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-content-save</v-icon></template
                  >保存</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  defineComponent,
  set,
} from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import utilFunc from "@/common/utilFunc.js";

export default defineComponent({
  props: ["sitelist", "working_is_auto"],
  setup(props, ctx) {
    const UpdateContentsRepository = repositoryFactory.get("updateContents");
    const ChildUpdateHeadersRepository =
      repositoryFactory.get("childUpdateHeaders");
    const propsSitelist = ref(props.sitelist);
    const refsiteContentComponent = ref();
    const state = reactive({
      // 更新間隔ダイアログ関連
      intervalDialog: false,
      site_content_id: null,
      site_name: "",
      site_content_name: "",
      intervalId: null,
      interval: null,
      min_interval: 10,
      max_interval: 1440,
      time_zone_list: [],
      time_zone_default_list: setting.time_zone_default.split(","),
      // 表関連
      search: "",
      headers: [
        {
          text: "更新する",
          value: "is_auto",
          sortable: false,
          width: 60,
          class: "td_enable",
        },
        {
          text: "次回更新",
          value: "next_update",
          sortable: false,
          align: "center",
          width: 100,
        },
        {
          text: "更新設定",
          value: "interval_minute",
          sortable: false,
          width: 150,
        },
        {
          text: "個別設定",
          value: "site_content_config",
          sortable: false,
          class: "td_site_content_config",
        },
        {
          text: "コンテンツ名",
          value: "site_content_name",
          sortable: false,
          class: "td_site_content_name",
        },
        {
          text: "媒体",
          value: "site_name",
          sortable: false,
          class: "td_site_name",
        },
        {
          text: "最終更新日",
          value: "last_update",
          sortable: false,
          width: 120,
        },
      ],
      // 個別設定関連
      baseComponents: {},
      siteContent: {},
    });

    // 初期化処理(同期処理)
    const init = async () => {
      // siteConfigComponentフォルダのコンポーネントを取得する
      const files = require.context("./siteConfigComponent", true, /\.vue$/);
      files.keys().forEach((key) => {
        set(
          state.baseComponents,
          key.replace(/(\.\/|\.vue)/g, ""),
          files(key).default
        );
      });
    };

    // 初期化する
    init();

    // 更新コンテンツテーブル保存
    const saveUpdateContent = async (item) => {
      store.dispatch("loadingIcon/showIcon");
      const params = {
        id: item.id,
        shop_site_id: item.shop_site_id,
        site_content_id: item.site_content_id,
        is_auto: item.is_auto,
        current_mode: setting.mode_timer,
        config: JSON.stringify(item.config),
        time_zone: item.time_zone,
      }; // current_modeは1固定(setting.mode_timer)
      const result = await UpdateContentsRepository.save_config(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            return response.data.id;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:working/component/siteList.vue/saveUpdateContent UpdateContentsRepository.save_config (" +
            error +
            ")"
          );
        });
      store.dispatch("loadingIcon/hideIcon");
      return result;
    };

    // 更新コンテンツテーブル保存
    const workingUpdateNext = async (item) => {
      store.dispatch("loadingIcon/showIcon");
      const params = {
        id: item.id,
        shop_site_id: item.shop_site_id,
        site_content_id: item.site_content_id,
        is_auto: item.is_auto,
        current_mode: setting.mode_timer,
        next_update_set: item.is_auto,
      }; // current_modeは1固定(setting.mode_timer)
      const result = await UpdateContentsRepository.working_update_next(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            return response.data;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:working/component/siteList.vue/workingUpdateNext UpdateContentsRepository.working_update_next (" +
            error +
            ")"
          );
        });
      store.dispatch("loadingIcon/hideIcon");
      return result;
    };

    // 更新コンテンツヘッダーテーブル保存
    const saveUpdateContentHeader = async (item) => {
      store.dispatch("loadingIcon/showIcon");
      const params = {
        id: item.child_update_header_id,
        update_content_id: item.id,
        mode: setting.mode_timer,
        interval_minute: item.interval_minute,
        tab_index: 0,
      }; // modeは1固定(setting.mode_timer)、tab_indexは0固定となる
      const result = await ChildUpdateHeadersRepository.save(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            return response.data.id;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:working/component/siteList.vue/saveUpdateContentHeader ChildUpdateHeadersRepository.save (" +
            error +
            ")"
          );
        });
      store.dispatch("loadingIcon/hideIcon");
      return result;
    };

    // 「更新する」クリック
    const onChangeListIsAuto = async (item) => {
      item.is_auto = !item.is_auto;
      // 個別設定がある場合、エラーがあったら処理を中断する
      if (
        setting.working_status_required_config_ids.includes(
          item.site_content_id
        )
      ) {
        if (item.is_auto && item.config_check === false) {
          alert(
            "個別設定が必要です。「更新設定」をクリックして設定内容を確認して下さい。"
          );
          item.is_auto = !item.is_auto;
          return;
        }
      }
      // 内部的に次回更新日時を取得しておく
      const response = await workingUpdateNext(item);
      if (!response.result) {
        alert("更新処理に失敗しました。処理を中断します。");
        item.is_auto = !item.is_auto;
        return;
      }
      item.id = response.update_content_id;
      item.child_update_header_id = response.child_update_header_id;
      item.next_update = utilDate.convertSplitStrToDate(response.next_update);

      // console.log(item);

      // 自動更新チェック（自動更新中に、「更新する」が全部OFFになった場合、自動更新を止める）
      if (!props.working_is_auto) {
        return;
      }
      let is_auto_check = false;
      for (let i = 0; i < propsSitelist.value.length; i++) {
        if (propsSitelist.value[i].is_auto) {
          is_auto_check = true;
          break;
        }
      }
      if (!is_auto_check) {
        // 「更新する」が全部OFF→自動更新を止める
        alert("「更新する」が全てOFFになりました。自動更新を停止します。");
        ctx.emit("stop_update");
      }
    };

    // 更新間隔クリック
    const onChangeInterval = async (item) => {
      state.intervalDialog = true;
      state.site_content_id = item.site_content_id;
      state.site_name = item.site_name;
      state.site_content_name = item.site_content_name;
      state.intervalId = item.id;
      state.interval = item.interval_minute;
      state.min_interval = item.site_content_min_interval;
      if (item.time_zone.length == 0) {
        state.time_zone_list = [];
      } else {
        state.time_zone_list = item.time_zone.split(",");
      }

      state.siteContent = {};
      if (
        setting.working_status_required_config_ids.includes(
          item.site_content_id
        )
      ) {
        // 個別設定が必要なコンテンツの場合、個別設定を取得する
        state.siteContent = {
          site_content_id: item.site_content_id,
          site_content_config: item.config,
          component: state.baseComponents[item.site_content_id],
        };
      }
    };

    // 更新間隔ダイアログ閉じる
    const cancelIntervalDialog = () => {
      state.intervalDialog = false;
      state.site_content_id = null;
      state.site_name = "";
      state.site_content_name = "";
      state.interval = null;
      state.min_interval = 10;
      state.time_zone_list.splice(0);
    };

    // 更新間隔ダイアログ保存
    const onSubmitIntervalDialog = async () => {
      // 入力チェック
      let isValid = await ctx.refs.observerInterval.validate();
      if (!isValid) {
        return false;
      }
      if (
        !window.confirm(
          "更新設定を変更します。よろしいですか？\n※次の更新後から新しい更新設定が反映されます"
        )
      ) {
        return false;
      }

      const index = propsSitelist.value.findIndex(
        (list) => list.id == state.intervalId
      );
      const saveSitelist = propsSitelist.value[index];
      console.log(saveSitelist);
      saveSitelist.interval_minute = state.interval;
      saveSitelist.time_zone = state.time_zone_list.join();
      // コンテンツ個別の設定項目がある場合、configに入れる
      if (Object.keys(state.siteContent).length === 0) {
        saveSitelist.config = null;
      } else {
        saveSitelist.config =
          await ctx.refs.refsiteContentComponent.getConfig();
      }
      // 保存時、config_checkの状態を更新
      saveSitelist.config_check = utilFunc.checkSiteContentConfig(
        saveSitelist.site_content_id,
        saveSitelist.config
      );

      // 更新コンテンツテーブル(update_contentsテーブルにレコードが無い場合、作っておく)
      saveSitelist.id = await saveUpdateContent(saveSitelist);
      // 更新コンテンツヘッダーテーブル
      saveSitelist.child_update_header_id = await saveUpdateContentHeader(
        saveSitelist
      );

      cancelIntervalDialog();
    };

    const onClickTimeZone = (num) => {
      const result = state.time_zone_list.indexOf(num);
      if (result === -1) {
        state.time_zone_list.push(num);
      } else {
        state.time_zone_list.splice(result, 1);
      }
    };

    return {
      setting,
      utilDate,
      propsSitelist,
      refsiteContentComponent,
      ...toRefs(state),
      onChangeListIsAuto,
      onChangeInterval,
      cancelIntervalDialog,
      onSubmitIntervalDialog,
      onClickTimeZone,
    };
  },
});
</script>

<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .mini label {
  font-size: 0.75em;
}
.no_top_margin .v-input--selection-controls,
.no_top_margin .v-text-field {
  margin-top: 0;
  padding-top: 0;
}
::v-deep .is_auto_cursor {
  cursor: pointer !important;
}
::v-deep .td_site_content_name {
  min-width: 150px;
  width: 250px;
}
::v-deep .td_site_content_config {
  min-width: 40px;
  width: 40px;
}
::v-deep .td_site_name {
  min-width: 200px;
}
::v-deep .td_content_name {
  min-width: 80px;
  width: 120px;
}
::v-deep .td_shop_site {
  min-width: 200px;
}
.shop_site_span {
  font-size: 0.75em;
  background-color: #fafafa;
  word-break: break-all;
}
.time_zone_box {
  display: inline-block;
}
.no_config_message {
  padding: 1em;
  background-color: #fafafa;
}
</style>
