<template>
  <div>
    <app-bar
      ref="refAppBar"
      v-bind:is_auto="wShop.is_auto"
      @update_button="updateButton"
      @sync_button="allSyncButton"
    ></app-bar>
    <div>
      <v-tabs v-model="items" class="mb-3">
        <v-tabs-slider></v-tabs-slider>
        <v-tab v-for="tab in tabs" :key="tab.index" class="font-weight-bold">{{
          tab.name
        }}</v-tab>
      </v-tabs>
      <v-tabs-items v-model="items" touchless>
        <v-tab-item key="1">
          <cast-list-component
            ref="refCastList"
            :dateline="dateline"
            v-bind:castlist="wCasts"
            v-bind:working_is_auto="wShop.is_auto"
            @reload="reload"
          ></cast-list-component>
        </v-tab-item>
        <v-tab-item key="2">
          <site-list-component
            ref="refSiteList"
            v-bind:sitelist="wSites"
            v-bind:working_is_auto="wShop.is_auto"
            @reload="reload"
            @stop_update="stopUpdate"
          ></site-list-component>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <!-- 自動更新ダイアログ -->
    <v-dialog
      v-model="updateDialog"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">自動更新を開始</span>
        </v-card-title>
        <v-card-text>
          <h3>初回の更新時刻を指定</h3>
          <v-radio-group v-model="updateMode">
            <v-radio
              v-for="rItem in radioItems"
              :key="rItem.index"
              :value="rItem.index"
            >
              <template v-slot:label
                >{{ rItem.label }}・・・{{ rItem.sub_label }}</template
              >
            </v-radio>
          </v-radio-group>
          <div>
            <v-container fluid>
              <v-row>
                <v-col cols="0" sm="1"></v-col>
                <v-col cols="6" sm="3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :disabled="updateMode != 2"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="updateDate"
                        label="日付選択"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :disabled="updateMode != 2"
                        hide-details="false"
                        dense
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="updateDate"
                      no-title
                      @input="menu = false"
                      :first-day-of-week="0"
                      locale="ja-jp"
                      :disabled="updateMode != 2"
                    >
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="6" sm="3">
                  <v-form ref="refForm">
                    <div class="d-flex flex-wrap">
                      <div class="flex_center">
                        <div class="select_item">
                          <v-select
                            class="select_hour"
                            v-model="updateHour"
                            :items="selectITem.hourItem"
                            item-text="text"
                            item-value="value"
                            label="時"
                            dense
                            hide-details="false"
                            :disabled="updateMode != 2"
                            :rules="[required]"
                          >
                          </v-select>
                        </div>
                      </div>
                      <div class="flex_center">
                        <div class="select_item ml-1">
                          <v-select
                            class="select_minute"
                            v-model="updateMinute"
                            :items="selectITem.minuteItem"
                            item-text="text"
                            item-value="value"
                            label="分"
                            dense
                            hide-details="false"
                            :disabled="updateMode != 2"
                            :rules="[required]"
                          ></v-select>
                        </div>
                      </div>
                    </div>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
            <v-subheader class="mt-5 pl-0">
              自動更新中は、各キャストのステータスにあわせて、即ヒメ・待機中・受付終了等の状態に自動で各媒体に更新します。
            </v-subheader>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelUpdateDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitUpdateDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-timer-outline</v-icon></template
                  >開始</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 一括更新ダイアログ -->
    <v-dialog
      v-model="allSyncDialog"
      scrollable
      persistent
      width="100%"
      max-width="600px"
    >
      <v-card :disabled="allSyncDialogDisabled">
        <v-card-title>
          <span class="text-h5">一括更新</span>
        </v-card-title>
        <v-card-text>
          <v-subheader class="pl-0">
            自動更新に関係なく、直ちに更新処理を行います。更新したい媒体にチェックして下さい。
          </v-subheader>
          <h3>一括更新する更新媒体を選択</h3>
          <v-container>
            <v-row>
              <v-col cols="12">
                <!-- テーブル -->
                <v-data-table
                  :headers="allSyncHeaders"
                  :items="allSyncSiteList"
                  :mobile-breakpoint="0"
                  item-key="uk"
                  show-select
                  :single-select="false"
                  v-model="allSyncSelected"
                  :footer-props="{ 'items-per-page-options': [-1] }"
                  hide-default-footer
                  @click:row="rowClick"
                >
                  <template v-slot:no-data>
                    表示するデータがありません
                  </template>
                  <!-- eslint-disable-next-line -->
                  <template v-slot:item.status="{ item }">
                    {{ item.status }}
                    &nbsp;
                    <v-progress-linear
                      v-if="item.status == '更新中'"
                      indeterminate
                      color="primary"
                    ></v-progress-linear>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-container>
            <v-row justify="end">
              <v-col cols="6" sm="3">
                <general-button
                  btn_color="gray"
                  btn_block
                  @click-event="cancelAllSyncDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-close-circle</v-icon></template
                  >キャンセル</general-button
                >
              </v-col>
              <v-col cols="6" sm="3">
                <general-button
                  btn_type="info"
                  btn_block
                  @click-event="onSubmitAllSyncDialog"
                  ><template v-slot:icon
                    ><v-icon left>mdi-sync</v-icon></template
                  >一括更新</general-button
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  ref,
  reactive,
  toRefs,
  set,
  defineComponent,
  onMounted,
  onUnmounted,
  watch,
} from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate.js";
import utilFunc from "@/common/utilFunc.js";
import selectITem from "@/common/selectItem.js";
import appBar from "./appBar.vue";
import castListComponent from "./component/castList.vue";
import siteListComponent from "./component/siteList.vue";
import router from "@/router/index";

export default defineComponent({
  components: {
    appBar,
    castListComponent,
    siteListComponent,
  },
  setup(props, ctx) {
    const WorkingCastsRepository = repositoryFactory.get("workingCasts");
    const WorkingShopsRepository = repositoryFactory.get("workingShops");
    const UpdateContentsRepository = repositoryFactory.get("updateContents");
    const SynchroRepository = repositoryFactory.get("synchro");
    const refAppBar = ref();
    const refCastList = ref();
    const refSiteList = ref();
    const refForm = ref();
    const items = ref(null);
    const tabs = [
      {
        index: 1,
        name: "キャスト別設定",
      },
      {
        index: 2,
        name: "媒体別設定",
      },
    ];
    const radioItems = [
      {
        index: 1,
        label: "いますぐ",
        sub_label: "初回の更新時刻が5分後に設定されます",
      },
      {
        index: 2,
        label: "時間指定",
        sub_label: "初回の更新時刻を指定できます",
      },
    ];
    const state = reactive({
      // 自動更新開始ダイアログ
      updateDialog: false,
      updateMode: 1,
      updateDate: null,
      updateHour: 0,
      updateMinute: 0,
      // 一括更新ダイアログ
      allSyncDialog: false,
      allSyncDialogDisabled: false,
      allSyncSiteList: [],
      allSyncSelected: [],
      allSyncHeaders: [
        {
          text: "コンテンツ",
          value: "site_content_name",
          sortable: false,
        },
        {
          text: "サイト名",
          value: "site_name",
          sortable: false,
        },
        {
          text: "ステータス",
          value: "status",
          sortable: false,
        },
      ],
      // 処理用配列
      wShop: {
        is_auto: false,
      },
      wCasts: [],
      wSites: [],
      shop_id: 0,
      dateline: setting.sys_dateline,
    });
    let setIntervalId;

    // 勤務状態キャスト データ取得
    const getDataWorkingCasts = async () => {
      const params = {
        shop_id: state.shop_id,
        work_date: utilDate.getDateFormatLong(
          utilDate.getToday(0, state.dateline)
        ),
      };
      await WorkingCastsRepository.list(params)
        .then((response) => {
          if (response.data) {
            // データ格納
            Object.keys(response.data).forEach(function (key) {
              const rd = response.data[key];
              const index = state.wCasts.findIndex(
                (list) => list.cast_id == rd.cast_id
              );
              const setTime =
                rd.service_end_time != null
                  ? rd.service_end_time.slice(0, 5)
                  : null;
              if (index == -1) {
                state.wCasts.push({
                  schedule_id: rd.schedule_id,
                  shop_id: rd.shop_id,
                  cast_id: rd.cast_id,
                  work_date: rd.work_date,
                  start_time: rd.start_time.slice(0, 5),
                  end_time: rd.end_time.slice(0, 5),
                  id: rd.id,
                  mode: rd.mode,
                  service_end_time: setTime,
                  name: rd.name,
                  image: rd.image,
                  working_priority:
                    rd.working_priority == "true" ? true : false,
                  working_enable: rd.working_enable == "true" ? true : false,
                  working_comment: rd.working_comment,
                  dateline: rd.dateline,
                  work_status: rd.work_status,
                  time_sort_key: rd.time_sort_key,
                });
              } else {
                set(state.wCasts[index], "schedule_id", rd.schedule_id);
                set(state.wCasts[index], "work_date", rd.work_date);
                set(
                  state.wCasts[index],
                  "start_time",
                  rd.start_time.slice(0, 5)
                );
                set(state.wCasts[index], "end_time", rd.end_time.slice(0, 5));
                set(state.wCasts[index], "id", rd.id);
                set(state.wCasts[index], "mode", rd.mode);
                set(state.wCasts[index], "service_end_time", setTime);
                set(state.wCasts[index], "name", rd.name);
                set(state.wCasts[index], "image", rd.image);
                set(
                  state.wCasts[index],
                  "working_priority",
                  rd.working_priority == "true" ? true : false
                );
                set(
                  state.wCasts[index],
                  "working_enable",
                  rd.working_enable == "true" ? true : false
                );
                set(state.wCasts[index], "working_comment", rd.working_comment);
                set(state.wCasts[index], "dateline", rd.dateline);
                set(state.wCasts[index], "work_status", rd.work_status);
                set(state.wCasts[index], "time_sort_key", rd.time_sort_key);
              }
            });
            // リストから消えるキャストが居た場合の処理
            const difference = state.wCasts.filter(
              (castData) =>
                response.data.findIndex(
                  (rd) => castData.cast_id == rd.cast_id
                ) == -1
            );
            Object.keys(difference).forEach(function (key) {
              for (let i = 0; i < state.wCasts.length; i++) {
                if (difference[key].cast_id == state.wCasts[i].cast_id) {
                  state.wCasts.splice(i, 1);
                  break;
                }
              }
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:working/index.vue/getDataWorkingCasts WorkingCastsRepository.list (" +
            error +
            ")"
          );
        });
    };

    // 勤務状態サイト データ取得
    const getDataWorkingSites = async () => {
      await UpdateContentsRepository.list_working(state.shop_id)
        .then((response) => {
          if (response.data) {
            // データ格納
            Object.keys(response.data).forEach(function (key) {
              const rd = response.data[key];
              const uk =
                String(rd.shop_site_id) + "_" + String(rd.site_content_id);
              const index = state.wSites.findIndex(
                (list) =>
                  String(list.shop_site_id) +
                    "_" +
                    String(list.site_content_id) ==
                  uk
              );
              const site_name =
                rd.site_name + (rd.memo ? "[" + rd.memo + "]" : "");
              if (index == -1) {
                state.wSites.push({
                  uk: uk,
                  id: rd.id ?? 0,
                  is_auto: rd.is_auto ?? false,
                  shop_site_id: rd.shop_site_id,
                  site_name: site_name,
                  site_content_name: rd.site_content_name,
                  site_content_min_interval: rd.site_content_min_interval,
                  memo: rd.memo,
                  color: rd.color,
                  site_content_id: rd.site_content_id,
                  start_update: utilDate.convertSplitStrToDate(rd.start_update),
                  last_update: utilDate.convertSplitStrToDate(rd.last_update),
                  next_update: utilDate.convertSplitStrToDate(rd.next_update),
                  next_child_update_content_id: rd.next_child_update_content_id,
                  child_update_header_id: rd.child_update_header_id ?? 0,
                  interval_minute:
                    rd.interval_minute ?? rd.site_content_min_interval,
                  time_zone: rd.time_zone ?? setting.time_zone_default,
                  config: JSON.parse(rd.config),
                  config_check: utilFunc.checkSiteContentConfig(
                    rd.site_content_id,
                    JSON.parse(rd.config)
                  ),
                });
              } else {
                set(state.wSites[index], "uk", uk);
                set(state.wSites[index], "id", rd.id ?? 0);
                set(state.wSites[index], "is_auto", rd.is_auto ?? false);
                set(state.wSites[index], "shop_site_id", rd.shop_site_id);
                set(state.wSites[index], "site_name", site_name);
                set(
                  state.wSites[index],
                  "site_content_name",
                  rd.site_content_name
                );
                set(
                  state.wSites[index],
                  "site_content_min_interval",
                  rd.site_content_min_interval
                );
                set(state.wSites[index], "memo", rd.memo);
                set(state.wSites[index], "color", rd.color);
                set(state.wSites[index], "site_content_id", rd.site_content_id);
                set(
                  state.wSites[index],
                  "start_update",
                  utilDate.convertSplitStrToDate(rd.start_update)
                );
                set(
                  state.wSites[index],
                  "last_update",
                  utilDate.convertSplitStrToDate(rd.last_update)
                );
                set(
                  state.wSites[index],
                  "next_update",
                  utilDate.convertSplitStrToDate(rd.next_update)
                );
                set(
                  state.wSites[index],
                  "next_child_update_content_id",
                  rd.next_child_update_content_id
                );
                set(
                  state.wSites[index],
                  "child_update_header_id",
                  rd.child_update_header_id ?? 0
                );
                set(
                  state.wSites[index],
                  "interval_minute",
                  rd.interval_minute ?? rd.site_content_min_interval
                );
                set(
                  state.wSites[index],
                  "time_zone",
                  rd.time_zone ?? setting.time_zone_default
                );
                set(state.wSites[index], "config", JSON.parse(rd.config));
                set(
                  state.wSites[index],
                  "config_check",
                  utilFunc.checkSiteContentConfig(
                    rd.site_content_id,
                    JSON.parse(rd.config)
                  )
                );
              }
            });
            // リストから消えるサイトが居た場合の処理
            const difference = state.wSites.filter(
              (siteData) =>
                response.data.findIndex(
                  (rd) => siteData.shop_site_id == rd.shop_site_id
                ) == -1
            );
            Object.keys(difference).forEach(function (key) {
              for (let i = 0; i < state.wSites.length; i++) {
                if (
                  difference[key].shop_site_id == state.wSites[i].shop_site_id
                ) {
                  state.wSites.splice(i, 1);
                  break;
                }
              }
            });
            // console.log(state.wSites);
          }
        })
        .catch((error) => {
          throw (
            "ERROR:working/index.vue/getDataWorkingCasts WorkingCastsRepository.list (" +
            error +
            ")"
          );
        });
    };

    // 初期化処理
    const init = async () => {
      store.dispatch("loadingIcon/showIcon");

      // 店舗チェック falseの場合ログアウトになる
      const currentShop = store.getters["shops/currentShop"];
      if (!(await utilFunc.checkShop(currentShop.id, router))) return;

      // キャスト別設定タブ データ取得
      state.shop_id = await store.getters["shops/currentShop"].id;
      state.dateline = await store.getters["shops/dateline"];

      // 勤務状態店舗 データ取得
      await WorkingShopsRepository.get_by_shopid(state.shop_id)
        .then((response) => {
          if (response.data) {
            // データ格納
            set(state.wShop, "is_auto", response.data.is_auto);
          }
        })
        .catch((error) => {
          throw (
            "ERROR:working/index.vue/init WorkingShopsRepository.list (" +
            error +
            ")"
          );
        });

      // キャスト別設定タブ データ取得
      state.wCasts.splice(0);
      await getDataWorkingCasts();

      // 媒体別設定タブ データ取得
      state.wSites.splice(0);
      await getDataWorkingSites();

      store.dispatch("loadingIcon/hideIcon");
    };

    init();

    // 再読み込み処理(選択中のタブで処理わける)
    const reload = async () => {
      if (items.value == 0) {
        await getDataWorkingCasts();
      } else {
        await getDataWorkingSites();
      }
    };

    const saveIsAuto = async (is_auto) => {
      // DB更新
      store.dispatch("loadingIcon/showIcon");
      const params = {
        shop_id: state.shop_id,
        is_auto: is_auto,
      };
      const result = await WorkingShopsRepository.save(params)
        .then((response) => {
          if (response.data) {
            // データ追加OK
            return true;
          }
        })
        .catch((error) => {
          throw (
            "ERROR:working/component/castList.vue/save_working_cast WorkingCastsRepository.save (" +
            error +
            ")"
          );
        });
      if (result) {
        set(state.wShop, "is_auto", is_auto);
      }
      store.dispatch("loadingIcon/hideIcon");
    };

    const updateButton = async () => {
      if (!state.wShop.is_auto) {
        // 開始処理
        // 自動更新が開始できるかチェック
        let auto_check = false;
        for (let i = 0; i < state.wSites.length; i++) {
          if (state.wSites[i].is_auto) {
            auto_check = true;
            break;
          }
        }
        if (!auto_check) {
          alert(
            "自動更新を開始できませんでした。\n「媒体別設定」タブにて、１つ以上「更新する」にチェックを入れて下さい。"
          );
          items.value = 1; // 「媒体別設定」タブに移動
          return false;
        }
        // ダイアログ表示
        const dt = new Date();
        dt.setMinutes(dt.getMinutes() + 10);
        state.updateDate = utilDate.getDateFormatLong(dt);
        state.updateHour = dt.getHours();
        state.updateMinute = dt.getMinutes();
        state.updateDialog = true;
      } else {
        // 停止処理
        if (
          window.confirm(
            "自動更新を停止すると勤務ステータスが更新されません。よろしいですか？"
          )
        ) {
          await updateFunc(false);
        }
      }
    };

    const cancelUpdateDialog = async () => {
      state.updateDialog = false;
      state.updateMode = 1;
      state.updateDate = null;
      state.updateHour = 0;
      state.updateMinute = 0;
    };

    const onSubmitUpdateDialog = async () => {
      // 入力チェック 過去の日付は指定できない
      if (state.updateMode == 2) {
        if (!refForm.value.validate()) {
          alert("時刻指定に誤りがあります。（必須入力です）");
          return false;
        }
        const checkDatetime =
          state.updateDate +
          " " +
          ("00" + state.updateHour).slice(-2) +
          ":" +
          ("00" + state.updateMinute).slice(-2);
        const limitDatetime = new Date();
        limitDatetime.setMinutes(limitDatetime.getUTCMinutes() + 5);
        if (utilDate.getDatetimeFormatLong(limitDatetime) > checkDatetime) {
          alert("現在時刻+5分以前の日時は指定できません。");
          return false;
        }
      }
      if (!window.confirm("自動更新を開始します。よろしいですか？")) {
        return false;
      }

      // 保存処理
      await updateFunc(true);
      await cancelUpdateDialog();
    };

    // 更新を開始または止める
    const updateFunc = async (is_auto_set) => {
      // 自動更新を開始する場合、次回媒体の更新日時をチェックし、過去の場合は再度次回更新日時を取得する
      if (is_auto_set) {
        const now = new Date();
        for (let i = 0; i < state.wSites.length; i++) {
          if (state.wSites[i].is_auto) {
            // 次回更新時刻を取得
            store.dispatch("loadingIcon/showIcon");
            let start_update = null;
            if (state.updateMode == 1) {
              start_update = new Date();
              start_update.setMinutes(start_update.getUTCMinutes() + 5);
              start_update =
                utilDate.getDatetimeFormatLong(start_update) + ":00";
            } else if (state.updateMode == 2) {
              start_update =
                state.updateDate +
                " " +
                ("00" + state.updateHour).slice(-2) +
                ":" +
                ("00" + state.updateMinute).slice(-2) +
                ":00";
            }
            const params = {
              id: state.wSites[i].id,
              shop_site_id: state.wSites[i].shop_site_id,
              site_content_id: state.wSites[i].site_content_id,
              is_auto: state.wSites[i].is_auto,
              current_mode: setting.mode_timer,
              start_update: start_update,
              next_update_set: state.wSites[i].is_auto,
            }; // current_modeは1固定(setting.mode_timer)
            const response = await UpdateContentsRepository.working_update_next(
              params
            )
              .then((response) => {
                if (response.data) {
                  // データ追加OK
                  return response.data;
                }
              })
              .catch((error) => {
                throw (
                  "ERROR:working/index.vue/updateButton UpdateContentsRepository.working_update_next (" +
                  error +
                  ")"
                );
              });
            store.dispatch("loadingIcon/hideIcon");
            if (!response.result) {
              const s_name =
                state.wSites[i].site_content_name +
                "（" +
                state.wSites[i].site_name +
                "）";
              alert(
                "次回の更新時刻が取得できませんでした。\n" +
                  "「" +
                  s_name +
                  "」" +
                  "の更新設定に誤りがないか確認して下さい。"
              );
              return false;
            }
            state.wSites[i].id = response.update_content_id;
            state.wSites[i].child_update_header_id =
              response.child_update_header_id;
            state.wSites[i].next_update = utilDate.convertSplitStrToDate(
              response.next_update
            );
          }
        }
      }

      await saveIsAuto(is_auto_set);
    };

    // 自動更新を止める
    const stopUpdate = async () => {
      await saveIsAuto(false);
    };

    const allSyncButton = async () => {
      state.allSyncSiteList.splice(0);
      state.allSyncSelected.splice(0);
      Object.keys(state.wSites).forEach(function (key) {
        const data = {
          uk: state.wSites[key].uk,
          shop_site_id: state.wSites[key].shop_site_id,
          site_content_id: state.wSites[key].site_content_id,
          site_name: state.wSites[key].site_name,
          site_content_name: state.wSites[key].site_content_name,
          status: null,
        };
        state.allSyncSiteList.push(data);
        if (state.wSites[key].is_auto) {
          state.allSyncSelected.push(data);
        }
      });
      state.allSyncDialog = true;
    };

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    const cancelAllSyncDialog = async () => {
      state.allSyncDialog = false;
    };

    const onSubmitAllSyncDialog = async () => {
      // ここに更新処理
      if (
        !window.confirm(
          "選択したコンテンツを更新を開始します。よろしいですか？"
        )
      ) {
        return false;
      }
      // ラベル表示
      for (let i = 0; i < state.allSyncSiteList.length; i++) {
        set(state.allSyncSiteList[i], "status", "");
      }
      // 順次更新処理を行う
      state.allSyncDialogDisabled = true;

      // ラベル表示
      for (let i = 0; i < state.allSyncSelected.length; i++) {
        set(state.allSyncSelected[i], "status", "処理待ち");
      }
      // 順次処理する
      let error_flg = false;
      for (let i = 0; i < state.allSyncSelected.length; i++) {
        set(state.allSyncSelected[i], "status", "更新中");

        const config_check = state.wSites.find(
          (list) =>
            list.shop_site_id == state.allSyncSelected[i].shop_site_id &&
            list.site_content_id == state.allSyncSelected[i].site_content_id
        ).config_check;
        if (config_check == false) {
          set(state.allSyncSelected[i], "status", "個別設定エラー");
          error_flg = true;
          continue;
        }

        // 更新処理
        store.dispatch("loadingIcon/showIcon");

        let params = new URLSearchParams();
        const shop_dateline = store.getters["shops/dateline"];
        params = {
          shop_id: store.getters["shops/currentShop"]["id"],
          shop_site_id: state.allSyncSelected[i].shop_site_id,
          site_content_id: state.allSyncSelected[i].site_content_id,
        };
        let result = false;
        let message = "";
        result = await SynchroRepository.sync_working(params)
          .then((response) => {
            if (response.data) {
              if (response.data.status) {
                return true;
              }
            } else {
              message = response.data.message;
              error_flg = true;
              return false;
            }
          })
          .catch((error) => {
            console.log(error);
            return false;
          });
        if (result) {
          set(state.allSyncSelected[i], "status", "更新完了");
        } else {
          set(state.allSyncSelected[i], "status", "更新失敗");
        }

        store.dispatch("loadingIcon/hideIcon");
      }

      state.allSyncDialogDisabled = false;
      if (!error_flg) {
        await cancelAllSyncDialog();
      }
    };

    // タイマー処理(毎分チェック、自動更新ONの時のみ)
    const timer = async () => {
      const now = new Date();
      // console.log("タイマー起動", now);
      if (!state.wShop.is_auto) {
        if (items.value == 0 && state.dateline == now.getHours()) {
          await getDataWorkingCasts();
          // console.log(items.value, "読込 日付変更");
        }
        return;
      }
      if (items.value == 0) {
        // キャスト別 10分に1回、再読み込み
        if (String(now.getMinutes()).slice(-1) == "0") {
          await getDataWorkingCasts();
          // console.log(items.value, "読込", state.wCasts);
        }
      } else {
        // サイト別 該当時刻に再読み込み
        const strNow = utilDate.getDatetimeFormatLongDisplay(now);
        let siteReload = false;
        for (let i = 0; i < state.wSites.length; i++) {
          if (state.wSites[i].is_auto) {
            const strNextUpdate = utilDate.getDatetimeFormatLongDisplay(
              state.wSites[i].next_update
            );
            // console.log("check", strNow, strNextUpdate);
            if (strNow >= strNextUpdate) {
              siteReload = true;
              break;
            }
          }
        }
        if (siteReload) {
          await getDataWorkingSites();
        }
      }
    };
    // タイマー開始
    onMounted(() => {
      setIntervalId = setInterval(timer, 60000);
    });
    // タイマー終了
    onUnmounted(() => clearInterval(setIntervalId));

    // タブを切り替えた時、再読み込みする
    watch(
      () => items.value,
      async (newValue, oldValue) => {
        if (oldValue != null) {
          await reload();
        }
      }
    );

    return {
      setting,
      utilDate,
      selectITem,
      refAppBar,
      refCastList,
      refSiteList,
      refForm,
      items,
      tabs,
      radioItems,
      ...toRefs(state),
      init,
      reload,
      getDataWorkingCasts,
      getDataWorkingSites,
      cancelUpdateDialog,
      onSubmitUpdateDialog,
      updateButton,
      updateFunc,
      cancelAllSyncDialog,
      onSubmitAllSyncDialog,
      allSyncButton,
      rowClick,
      saveIsAuto,
      stopUpdate,
      menu: false,
      required: (value) => value != null,
    };
  },
});
</script>

<style scoped>
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px;
  z-index: 100;
}
.select_item {
  width: 4em;
}
</style>
